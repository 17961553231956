import React, { useEffect, useRef, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { createWinery } from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { imageUpload } from "../../services/AuthService";
import moment from "moment";
import crossIcon from "../../images/svg/cross-circle-svgrepo-com.svg";
import ButtonLoader from "../common/ButtonLoader";
export default function CreateWinery(props) {
  const vendorId = localStorage.getItem("vendorId");
  //Modal box
  let errorsObj = {
    name: "",
    address: "",
    overview: "",
    price: "",
    guests: "",
    category: "",
    date: "",
    days: "",
    amenities: "",
    photo: "",
    startTime: "",
    endTime: "",
    openingHours: "",
  };
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    latitude: "",
    longitude: "",
    region: "",
    overview: "",
    guests: "",
    price: "",
    photo: [],
    category: "",
    cancelledDates: [],
    amenities: [],
  });

  const [errors, setErrors] = useState(errorsObj);
  const [newText, setNewText] = useState("");
  const [reference, setReference] = useState([]);
  const [currentEditIndex, setCurrentEditIndex] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loader, setLoader] = useState(false);
  const format = "DD/MM/YYYY";
  const [dates, setDates] = useState([
    new DateObject().set({ day: null, format }),
  ]);
  console.log(dates, "dates");
  const formattedDates = dates?.map((date, index) => {
    return date.format();
  });

  let zipCode = "";
  let city = "";

  const [formDataTime, setFormDataTime] = useState({
    Sunday: { startTime: "", endTime: "", timeSlots: [] },
    Monday: { startTime: "", endTime: "", timeSlots: [] },
    Tuesday: { startTime: "", endTime: "", timeSlots: [] },
    Wednesday: { startTime: "", endTime: "", timeSlots: [] },
    Thursday: { startTime: "", endTime: "", timeSlots: [] },
    Friday: { startTime: "", endTime: "", timeSlots: [] },
    Saturday: { startTime: "", endTime: "", timeSlots: [] },
  });
  const formDataTimeWithoutEmptyKeys = Object.keys(formDataTime).reduce(
    (acc, day) => {
      if (formDataTime[day].timeSlots.length > 0) {
        acc.push({ day, slots: formDataTime[day].timeSlots });
      }
      return acc;
    },
    []
  );

  const handleAddText = (e) => {
    e.preventDefault();
    const newArr = reference;
    if (newText.trim() && newText != "") {
      newArr.push(newText.trim());
    }
    setReference(newArr);
    setNewText("");
  };
  const handleUpdateText = () => {
    // ...
    const updatedReference = [...reference];
    updatedReference[currentEditIndex] = newText;
    setReference(updatedReference);
    setNewText("");
    setIsEdit(false);
  };
  const handleEdit = (index, item) => {
    // Set newText state to the item being edited
    setNewText(item);
    // Set the currentEditIndex state to the index of the item being edited
    setCurrentEditIndex(index);
    // Set isEdit state to true to indicate that we're in edit mode
    setIsEdit(true);
  };
  const handleDelete = (index) => {
    const newReferences = [...reference];
    newReferences.splice(index, 1);
    setReference(newReferences);
  };
  const [uploadedImages, setUploadedImages] = useState([]); // State to store image URLs

  const handleInputChange = (e) => {
    const selectedFiles = e.target.files;

    // Check if no files are selected
    if (selectedFiles.length === 0) {
      setErrors({ ...errors, photo: "No files selected" });
      return;
    }
    // Ensure the number of images doesn't exceed 5
    if (uploadedImages.length + selectedFiles.length > 5) {
      setErrors({ ...errors, photo: "You can only upload up to 5 images" });
      return;
    }

    const uploadPromises = [];
    setLoader(true);
    // Loop through each selected file and upload
    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];

      if (selectedFile) {
        const uploadPromise = imageUpload(selectedFile, vendorId)
          .then((response) => {
            return response.data?.data?.photo; // Return the image URL
          })
          .catch((error) => {
            console.log(error, "image upload error");
            return null; // Return null if an error occurs
          });
        uploadPromises.push(uploadPromise);
      }
    }

    // Wait for all promises to resolve
    Promise.all(uploadPromises).then((photoUrls) => {
      const validPhotos = photoUrls.filter((url) => url !== null); // Filter out any failed uploads

      // Update the state with the new images, ensuring no more than 5
      setUploadedImages((prevImages) => {
        const updatedImages = [...prevImages, ...validPhotos];
        setLoader(false);
        if (updatedImages.length > 5) {
          setErrors({ ...errors, photo: "You can only upload 5 images" });
          return prevImages; // Return previous state if more than 5
        }

        setErrors({ ...errors, photo: "" });
        return updatedImages;
      });
    });
  };

  const handleAddress = (place) => {
    console.log(place, "address list");

    geocodeByAddress(place.label)
      .then((results) => {
        console.log(results, "address list results");

        if (results && results.length > 0) {
          const addressComponents = results[0]?.address_components || [];

          const city =
            addressComponents.find(
              (component) =>
                component.types.includes("locality") ||
                component.types.includes("administrative_area_level_2")
            )?.long_name || "";

          const zipCode =
            addressComponents.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";

          return getLatLng(results[0]);
        } else {
          throw new Error("No results found for the provided address.");
        }
      })
      .then((res) => {
        console.log(res, "lat lng res");
        setFormData({
          ...formData,
          address: place?.label,
          latitude: res.lat,
          longitude: res.lng,
        });
        setErrors({ ...errors, address: "" });
      })
      .catch((error) => {
        console.error("Error getting address details:", error);
      });
  };
  const handleAddTime = (day) => {
    const { startTime, endTime } = formDataTime[day];
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);

    if (startTime && endTime && end > start) {
      setFormDataTime((prevState) => ({
        ...prevState,
        [day]: {
          ...prevState[day],
          timeSlots: [...prevState[day].timeSlots, { startTime, endTime }],
          startTime: "",
          endTime: "",
        },
      }));
    } else {
      setErrors({
        ...errors,
        [day]: "End time must be greater than start time.",
      });
    }
  };
  const handleDeleteTime = (day, index) => {
    const updatedTimeSlots = [...formDataTime[day].timeSlots];
    updatedTimeSlots.splice(index, 1);

    setFormDataTime((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        timeSlots: updatedTimeSlots,
      },
    }));
  };
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  async function onPostData(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (uploadedImages.length === 0) {
      errorObj.photo = "This field is required";
      error = true;
    }
    if (uploadedImages.length < 5) {
      errorObj.photo = "Please select 5 images";
      error = true;
    }
    if (formData.name.trim() === "") {
      errorObj.name = "This field is required";
      error = true;
    }
    if (formData.address.trim() === "") {
      errorObj.address = "This field is required";
      error = true;
    }
    if (formData.guests.trim() === "") {
      errorObj.guests = "This field is required";
      error = true;
    }
    if (formData.price.trim() === "") {
      errorObj.price = "This field is required";
      error = true;
    }
    if (formData.category === "") {
      errorObj.category = "This field is required";
      error = true;
    }
    if (formData.overview.trim() === "") {
      errorObj.overview = "This field is required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await createWinery({
        ...formData,
        photo: uploadedImages,
        amenities: reference,
        cancelledDates: formattedDates,
        openingHours: formDataTimeWithoutEmptyKeys,
      });
      notifyTopRight(response.data?.message);
      props.history.push("/winery-management");
      console.log(response.data.data.users);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error, "error");
    } finally {
    }
  }
  function generateTimeOptions() {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        const formattedHour = String(hour).padStart(2, "0");
        const formattedMinute = String(minute).padStart(2, "0");
        times.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return times;
  }

  const timeOptions = generateTimeOptions();

  function TimeInput({ label, value, onChange }) {
    return (
      <div className="w-100">
        <label>{label}</label>
        <input
          type="time"
          className="form-control "
          list="time-options"
          value={value}
          onChange={onChange}
        />
        <datalist id="time-options">
          {timeOptions?.map((time, index) => (
            <option key={index} value={time}></option>
          ))}
        </datalist>
      </div>
    );
  }

  return (
    <div className="card">
      <form>
        <div className="card-header">
          <h4 className="card-title fs-20">Add Details</h4>
        </div>
        <div className="card-body">
          <div className="add-contact-box">
            <div className="add-contact-content">
              <div className="field-wrapper">
                <div className="form-group mb-3 w-50">
                  <label className="text-black font-w500">Images</label>
                  <small>({uploadedImages?.length} out of 5)</small>{" "}
                  {loader && <p className="mb-0 text-black">Uploading...</p>}
                  <div className="contact-name">
                    <input
                      type="file"
                      accept=".png,.jpg,.jpeg"
                      className="form-control"
                      name="name"
                      onChange={handleInputChange}
                    />
                    <span className="validation-text"></span>
                    {errors.photo && (
                      <div className="text-danger fs-12">{errors.photo}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3 w-50">
                  <label className="text-black font-w500">Name</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={(e) => {
                        setFormData({ ...formData, name: e.target.value });
                        setErrors({ ...errors, name: "" });
                      }}
                    />
                    <span className="validation-text"></span>
                    {errors.name && (
                      <div className="text-danger fs-12">{errors.name}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="field-wrapper">
                <div className="form-group mb-3 w-50">
                  <label className="text-black font-w500">Guest</label>
                  <div className="contact-name">
                    <input
                      type="number"
                      className="form-control"
                      autocomplete="off"
                      name="department"
                      value={formData.guests}
                      min={1}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          guests: e.target.value,
                        });
                        setErrors({ ...errors, guests: "" });
                      }}
                    />
                    <span className="validation-text"></span>
                    {errors.guests && (
                      <div className="text-danger fs-12">{errors.guests}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3 w-50">
                  <label className="text-black font-w500">Price</label>
                  <div className="contact-name">
                    <input
                      type="number"
                      className="form-control"
                      autocomplete="off"
                      name="department"
                      value={formData.price}
                      min={0}
                      onChange={(e) => {
                        setFormData({ ...formData, price: e.target.value });
                        setErrors({ ...errors, price: "" });
                      }}
                    />
                    <span className="validation-text"></span>
                    {errors.price && (
                      <div className="text-danger fs-12">{errors.price}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="field-wrapper">
                <div className="form-group mb-3 w-100">
                  <label className="text-black font-w500">Address</label>
                  <div className="contact-name">
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                      selectProps={{
                        placeholder: "Enter Address",
                        inputValue: inputValue,
                        onInputChange: (value) => setInputValue(value),
                        onChange: handleAddress,
                        noOptionsMessage: () =>
                          inputValue ? "No options" : null, // Hide "No options" when input is empty
                        className: "form-control",
                      }}
                      autocompletionRequest={{
                        input: inputValue,
                        componentRestrictions: { country: ["CY", "GR"] }, // Restrict to Greece
                      }}
                    />
                    {errors.address && (
                      <div className="text-danger fs-12">{errors.address}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3 w-100">
                  <label className="text-black font-w500">Region</label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      value={formData.region}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          region: e.target.value,
                        });
                        setErrors({ ...errors, region: "" });
                      }}
                    >
                      <option hidden>Select</option>
                      <option>Paphos</option>
                      <option>Limassol</option>
                      <option>Nicosia</option>
                      <option>Larnaca</option>
                      <option>Ammochostos</option>
                      
                    </select>

                    <span className="validation-text"></span>
                    {errors.region && (
                      <div className="text-danger fs-12">{errors.region}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="field-wrapper">
                <div className="form-group mb-3 w-100">
                  <label className="text-black font-w500">Category</label>
                  <div className="contact-name">
                    <select
                      className="form-control"
                      value={formData.category}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          category: e.target.value,
                        });
                        setErrors({ ...errors, category: "" });
                      }}
                    >
                      <option hidden>Select</option>
                      <option>Wine Tasting</option>
                      <option>Wine Tours</option>
                      <option>Others</option>
                    </select>

                    <span className="validation-text"></span>
                    {errors.category && (
                      <div className="text-danger fs-12">{errors.category}</div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3 w-100">
                  <label className="text-black font-w500">
                    Cancelled Dates
                  </label>
                  <div className="contact-name">
                    <DatePicker
                      value={dates}
                      onChange={setDates}
                      multiple
                      sort
                      format={format}
                      calendarPosition="bottom-center"
                      // plugins={[<DatePanel />]}
                      minDate={new Date()}
                    />

                    <span className="validation-text"></span>
                    {errors.date && (
                      <div className="text-danger fs-12">{errors.date}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group mb-3">
                <label className="text-black font-w500">Time Slots</label>
                <div>
                  {days.map((day) => (
                    <div
                      key={day}
                      className="contact-name d-flex flex-column mb-3"
                      style={{ gap: "10px" }}
                    >
                      {/* Day Label */}
                      <div
                        className="d-flex justify-content-between align-items-end mb-2"
                        style={{ gap: "10px" }}
                      >
                        <div className="w-50">
                          <label className="text-black">{day}</label>
                        </div>
                        {/* Start Time Input */}
                        <div className="w-100">
                          <TimeInput
                            label="Start Time"
                            value={formDataTime[day].startTime}
                            onChange={(e) =>
                              setFormDataTime({
                                ...formDataTime,
                                [day]: {
                                  ...formDataTime[day],
                                  startTime: e.target.value,
                                },
                              })
                            }
                          />
                        </div>

                        {/* End Time Input */}
                        <div className="w-100">
                          <TimeInput
                            label="End Time"
                            value={formDataTime[day].endTime}
                            onChange={(e) =>
                              setFormDataTime({
                                ...formDataTime,
                                [day]: {
                                  ...formDataTime[day],
                                  endTime: e.target.value,
                                },
                              })
                            }
                          />
                        </div>

                        {/* Add Button */}
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleAddTime(day)}
                        >
                          Add
                        </button>
                      </div>

                      {/* Display Slots Below the Inputs */}
                      {formDataTime[day].timeSlots.length > 0 && (
                        <div>
                          {formDataTime[day].timeSlots.map((slot, index) => (
                            <button
                              type="button"
                              className="btn btn-outline-secondary mr-2 mb-2"
                              key={index}
                            >
                              {moment(slot.startTime, "HH:mm").format(
                                "hh:mm a"
                              )}{" "}
                              -{" "}
                              {moment(slot.endTime, "HH:mm").format("hh:mm a")}
                              <img
                                src={crossIcon}
                                width={20}
                                className="ml-2"
                                onClick={() => handleDeleteTime(day, index)}
                              />
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="form-group mb-3">
                <label className="text-black font-w500">Amenities</label>
                {reference?.length > 0 && (
                  <div className=" mb-2">
                    <ul>
                      {reference?.map((item, index) => (
                        <div
                          className="d-flex justify-content-between fs-14"
                          key={index}
                        >
                          <li style={{ maxWidth: "400px" }}>{item} </li>
                          <div>
                            <i
                              className="flaticon-381-edit-1 pointer"
                              onClick={() => handleEdit(index, item)}
                            ></i>
                            <i
                              className="flaticon-381-trash-1 pointer ml-2"
                              onClick={() => handleDelete(index)}
                            ></i>
                          </div>
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="contact-name d-flex">
                  <input
                    type="text"
                    className="form-control mr-2"
                    value={newText}
                    onChange={(e) => {
                      setNewText(e.target.value);
                      setErrors({ ...errors, amenities: "" });
                    }}
                  />
                  <button
                    type="button"
                    className="btn btn-primary btn-color py-1 px-3"
                    onClick={isEdit ? handleUpdateText : handleAddText}
                  >
                    {isEdit ? "Update" : "Add"}
                  </button>
                </div>
              </div>

              <div className="form-group mb-0">
                <label className="text-black font-w500">Overview</label>
                <div className="contact-name">
                  <textarea
                    type="text"
                    rows={4}
                    className="form-control"
                    autocomplete="off"
                    name="department"
                    value={formData.overview}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        overview: e.target.value,
                      });
                      setErrors({ ...errors, overview: "" });
                    }}
                  />
                  <span className="validation-text"></span>
                  {errors.overview && (
                    <div className="text-danger fs-12">{errors.overview}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-right">
          <button
            type="button"
            className="btn btn-primary fs-14 py-2 px-3"
            onClick={onPostData}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
