import React, { useEffect, useState } from "react";
import { Dropdown, Card, Badge } from "react-bootstrap";
import CountUp from "react-countup";
// Images
import {
  dashboard,
  fileUpload,
  stipeLink,
} from "../../../services/AuthService";
import { notifyError, notifyTopRight } from "../../common/Toaster";
import Spinner from "../../common/Spinner";
import svgIcon from "../../../images/svg/Vector (3).svg";
import BarChart1 from "./BarChart1";
import { getChartsData } from "../../../services/Bookings/BookingService";
import { getFormatedGraphData } from "./GraphData";
import euroSvg from "../../../images/svg/euro-currency-symbol.svg";
import ButtonLoader from "../../common/ButtonLoader";
const Home = (props) => {
  let errorsObj = { file: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [users, setUsers] = useState(null);
  const [file, setFile] = useState("");
  const [filter, setFilter] = useState("day");
  const isVerified = localStorage.getItem("isVerified");
  const isDocumentUploaded = localStorage.getItem("isDocumentUploaded");
  const verification = localStorage.getItem("verificationStatus");
  console.log(
    isVerified,
    isDocumentUploaded,
    verification,
    "isDocumentUploaded"
  );
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [chartData, setChartData] = useState([]);
  console.log(users, "users");
  function getDashboardData() {
    // setLoader(true);
    dashboard(filter)
      .then((response) => {
        setUsers(response.data?.data);
      })
      .catch((error) => {
        // console.log(error?.response, "heloo");
      });
  }
  async function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (!file) {
      setErrors({ file: "Please select a file." });
      error = true;

      return;
    }

    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await fileUpload(file);
      // console.log(response.data, "data");
      notifyTopRight("Uploaded Successfully.");
      getDashboardData();
      setFile("");
    } catch (error) {
      notifyError(error.response.data?.message);
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  }

  ///function to fetch chart data ///
  const handleFetchChart = async () => {
    setLoader2(true);
    try {
      const response = await getChartsData(filter);
      // console.log(response.data, "chart data");
      setChartData(response?.data?.data?.result);
      setLoader2(false);
    } catch (error) {
      console.log(error, "error");

      setLoader2(false);
    }
  };
  async function onContinue() {
    setLoader(true);
    try {
      const response = await stipeLink();
      console.log(response.data.data, " response");
      window.open(response?.data?.data?.url, "_self");
      setLoader(true);
    } catch (error) {
      notifyError(error.response.data.message);
      console.log(error, "error");
    }
  }
  useEffect(() => {
    getDashboardData();
    handleFetchChart();
  }, [filter]);

  return (
    <>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className=" mr-auto d-lg-block ">
          <h2 className="text-black font-w600 mb-0">Dashboard</h2>
          <p className="mb-0">Welcome Winery Vendor!</p>

          {users?.vendor?.isVerified === false &&
            (users?.vendor?.isRequested === true &&
            users?.vendor?.verification === "pending" ? (
              <p className="text-primary">Your document is under review.</p>
            ) : users?.vendor?.verification === "reject" ? (
              <div className="mt-2">
                <Badge variant="danger light">Rejected</Badge>
                <p className="text-danger">
                  <b>Reason:</b> {users?.vendor?.message}
                </p>
              </div>
            ) : (
              <p className="text-danger">
                Your account is not verified yet! Please upload a document to
                verify your account.
              </p>
            ))}
        </div>
        {users?.vendor?.stripeForm ? (
          <p className="text-success mb-0">{"Stripe Account Connected"}</p>
        ) : (
          <button
            type="button"
            className="btn btn-primary px-3 py-2 fs-12 mt-3"
            onClick={onContinue}
            disabled={loader}
          >
            {loader ? (
              <>
                <ButtonLoader /> Stripe connect
              </>
            ) : (
              "Stripe connect"
            )}
          </button>
        )}
      </div>

      {users?.vendor?.isVerified === false && !loader ? (
        users?.vendor?.isRequested === true &&
        users?.vendor?.verification === "pending" ? (
          ""
        ) : (
          <div className="col-lg-8 col-md-7 p-0">
            <div className="authincation-content text-black p-5">
              <div className="mb-4">
                <h3 className="mb-1 font-w600 text-black ">Upload Document</h3>
              </div>
              {loader ? (
                <Spinner />
              ) : (
                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">Document</strong>
                    </label>
                    <div className="mt-1 mb-2">
                      <p className="mb-0 fs-12 text-dark">
                        <span className="text-danger">*</span> Winery license
                      </p>
                      <p className="mb-0 fs-12 text-dark">
                        <span className="text-danger">*</span> Proof of Address
                        ( e.g. Utility bill etc) not older than 6 months
                      </p>
                    </div>
                    <input
                      type="file"
                      className="form-control"
                      accept=".pdf,.docx,.png,.jpg,.jpeg"
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setErrors({ ...errors, file: "" });
                      }}
                      placeholder="Enter title"
                    />
                    {errors.file && (
                      <div className="text-danger fs-12">{errors.file}</div>
                    )}
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary btn-block">
                      Send
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        )
      ) : (
        ""
      )}
      {users?.vendor?.isVerified && (
        <>
          <div className="row">
            <div
              className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6 pointer"
              onClick={() =>
                props.history.push({
                  pathname: "/winery-management",
                })
              }
            >
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-warning text-warning">
                      <img src={svgIcon} width={30} />
                    </span>
                    <div className="media-body">
                      <h3 className="mb-0 text-black">
                        <span className="counter ml-0">
                          {" "}
                          <CountUp
                            start={0}
                            end={users?.wineryCount || 0}
                            duration={5}
                          />
                        </span>
                      </h3>
                      <p className="mb-0">Total Wineries</p>
                      {/* <small>4% (30 days)</small> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6 pointer"
              onClick={() =>
                props.history.push({
                  pathname: "/booking-management",
                })
              }
            >
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-warning text-theme">
                      <i className="flaticon-381-bookmark-1"></i>
                      {/* <img src={svgIcon} width={30} /> */}
                    </span>
                    <div className="media-body">
                      <h3 className="mb-0 text-black">
                        <span className="counter ml-0">
                          {" "}
                          <CountUp
                            start={0}
                            end={users?.totalBookingCount || 0}
                            duration={5}
                          />
                        </span>
                      </h3>
                      <p className="mb-0">Total Bookings</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6 pointer"
              onClick={() =>
                props.history.push({
                  pathname: "/booking-management",
                  state: "active",
                })
              }
            >
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-warning text-theme">
                      <i className="flaticon-381-bookmark-1"></i>
                      {/* <img src={svgIcon} width={30} /> */}
                    </span>
                    <div className="media-body">
                      <h3 className="mb-0 text-black">
                        <span className="counter ml-0">
                          {" "}
                          <CountUp
                            start={0}
                            end={users?.activeBookingCount || 0}
                            duration={5}
                          />
                        </span>
                      </h3>
                      <p className="mb-0">Upcoming Bookings</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6 pointer"
              onClick={() =>
                props.history.push({
                  pathname: "/booking-management",
                  state: "confirm",
                })
              }
            >
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-warning text-theme">
                      <i className="flaticon-381-bookmark-1"></i>
                      {/* <img src={svgIcon} width={30} /> */}
                    </span>
                    <div className="media-body">
                      <h3 className="mb-0 text-black">
                        <span className="counter ml-0">
                          {" "}
                          <CountUp
                            start={0}
                            end={users?.confirmedBookingCount || 0}
                            duration={5}
                          />
                        </span>
                      </h3>
                      <p className="mb-0">Completed Bookings</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6 pointer"
              onClick={() =>
                props.history.push({
                  pathname: "/booking-management",
                  state: "cancel",
                })
              }
            >
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-warning text-theme">
                      <i className="flaticon-381-bookmark-1"></i>
                      {/* <img src={svgIcon} width={30} /> */}
                    </span>
                    <div className="media-body">
                      <h3 className="mb-0 text-black">
                        <span className="counter ml-0">
                          {" "}
                          <CountUp
                            start={0}
                            end={users?.canceledBookingCount || 0}
                            duration={5}
                          />
                        </span>
                      </h3>
                      <p className="mb-0">Cancelled Bookings</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-warning text-theme">
                      {/* <i className="flaticon-381-bookmark-10">€</i> */}
                      <img src={euroSvg} width={30} />
                    </span>
                    <div className="media-body">
                      <h3 className="mb-0 text-black">
                        <span className="counter ml-0">
                          {" "}
                          <CountUp
                            start={0}
                            end={users?.vendorAmount || 0}
                            duration={5}
                          />
                        </span>
                      </h3>
                      <p className="mb-0">Total Earnings</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-xxl-3 col-lg-6 col-md-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="mr-3 bgl-warning text-theme">
                      {/* <i className="flaticon-381-bookmark-10">€</i> */}
                      <img src={euroSvg} width={30} />
                    </span>
                    <div className="media-body">
                      <h3 className="mb-0 text-black">
                        <span className="counter ml-0">
                          {" "}
                          <CountUp
                            start={0}
                            end={users?.vendorUpcomingAmount || 0}
                            duration={5}
                          />
                        </span>
                      </h3>
                      <p className="mb-0">Upcoming Earnings</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-xxl-6 col-lg-6 col-sm-12">
              <Card>
                <Card.Header>
                  <Card.Title>Bookings vs Day</Card.Title>
                  {isVerified === "true" && (
                    <Dropdown className="dropdown custom-dropdown ml-2">
                      <Dropdown.Toggle
                        className="btn btn-sm btn-primary light d-flex align-items-center svg-btn"
                        data-toggle="dropdown"
                      >
                        <svg
                          width={28}
                          height={28}
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              d="M22.4281 2.856H21.8681V1.428C21.8681 0.56 21.2801 0 20.4401 0C19.6001 0 19.0121 0.56 19.0121 1.428V2.856H9.71606V1.428C9.71606 0.56 9.15606 0 8.28806 0C7.42006 0 6.86006 0.56 6.86006 1.428V2.856H5.57206C2.85606 2.856 0.560059 5.152 0.560059 7.868V23.016C0.560059 25.732 2.85606 28.028 5.57206 28.028H22.4281C25.1441 28.028 27.4401 25.732 27.4401 23.016V7.868C27.4401 5.152 25.1441 2.856 22.4281 2.856ZM5.57206 5.712H22.4281C23.5761 5.712 24.5841 6.72 24.5841 7.868V9.856H3.41606V7.868C3.41606 6.72 4.42406 5.712 5.57206 5.712ZM22.4281 25.144H5.57206C4.42406 25.144 3.41606 24.136 3.41606 22.988V12.712H24.5561V22.988C24.5841 24.136 23.5761 25.144 22.4281 25.144Z"
                              fill="#2F4CDD"
                            />
                          </g>
                        </svg>
                        <div className="text-left ml-3">
                          <span className="d-block fs-16">
                            {filter
                              ? filter.charAt(0).toUpperCase() + filter.slice(1)
                              : "Time Period"}
                          </span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        <Dropdown.Item
                          className="dropdown-item"
                          to="#"
                          onClick={() => setFilter("day")}
                        >
                          Today
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item"
                          to="#"
                          onClick={() => setFilter("week")}
                        >
                          This Week
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item"
                          to="#"
                          onClick={() => setFilter("month")}
                        >
                          This Month
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="dropdown-item"
                          to="#"
                          onClick={() => setFilter("year")}
                        >
                          This Year
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Card.Header>
                {loader2 ? (
                  <Spinner />
                ) : (
                  <Card.Body>
                    {chartData?.length > 0 && (
                      <BarChart1
                        chartData={getFormatedGraphData(chartData, filter)}
                      />
                    )}
                  </Card.Body>
                )}
              </Card>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
