import moment from "moment";
import React from "react";

export default function TimeSlot({
  text,
  slotData,
  selectedSlot,
  setSelectedSlot,
}) {
  console.log(slotData, "slotdata");
  return (
    <div className="mb-3">
      <div className="">
        {slotData?.map((item) => (
          <button
            type="button"
            className={`btn theme-outline-btn mr-2 mb-2 ${
              selectedSlot?._id === item?._id ? "focused" : ""
            }`}
            key={item?._id}
            onClick={() => setSelectedSlot(item)}
          >
            {moment(item?.startTime, "HH:mm").format("hh:mm a")} -{" "}
            {moment(item?.endTime, "HH:mm").format("hh:mm a")}
          </button>
        ))}
      </div>
    </div>
  );
}
