import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import yellowStar from "../../images/svg/yellowStart.svg";
import moment from "moment";
import ReviewCard from "../components/ReviewCard";

export default function UserDetails(props) {
  const imgUrl = "https://staging-api.bookwineries.com/";
  const data = props.location.state.data;
  console.log(data);
  return (
    <div>
      <div className="page-titles">
        <h4>Winery Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="winery-management">Winery Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Winery Details</Link>
          </li>
        </ol>
      </div>
      <Card style={{ width: "100%" }}>
        <Card.Header className="d-block align-items-start">
          <div className="text-left flex-grow-1">
            <div className="d-flex justify-content-between">
              <h3 className="text-color">{data?.name}</h3>
            </div>
            <div className="d-block d-md-flex">
              <div>
                <p>
                  Guests - <b>{data?.guests}</b>
                </p>
                <p>
                  Category - <b>{data?.category}</b>
                </p>
                <p>
                  Address - <b>{data?.address}</b>
                </p>
                <p>
                  Price - <b>€{data?.price}</b>
                </p>
                <p>
                  Total Review - <b>{data?.reviews?.length}</b>
                </p>
                <p>
                  Avg. Stars -{" "}
                  <b>
                    {data?.averageStars}
                    <img src={yellowStar} width={15} className="ml-1 mb-1" />
                  </b>
                </p>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-black">
            <h4 className="text-black">Images</h4>
            <div className="grid-images">
              {data?.photo &&
                data?.photo?.map((item, index) => (
                  <img src={imgUrl + item} width={80} height={80} />
                ))}
            </div>
            <h4 className="text-black mt-2">Time Slots</h4>
            <div className="mt-3">
              {data?.openingHours &&
                data.openingHours?.map((item, index) => (
                  <>
                    <p>{item?.day}</p>
                    {item?.slots?.map((slot) => (
                      <button
                        className="btn btn-outline-secondary me-2 mb-2 mr-2"
                        disabled
                        key={slot?._id}
                      >
                        {moment(slot?.startTime, "HH:mm").format("hh:mm a")} -{" "}
                        {moment(slot?.endTime, "HH:mm").format("hh:mm a")}
                      </button>
                    ))}
                  </>
                ))}
            </div>
            <h4 className="text-black mt-2">Cancelled Dates</h4>
            <div className="mt-3">
              {data?.frontCancelledDates && (
                <p>
                  {data?.frontCancelledDates
                    .map((item) => moment(item).format("DD/MM/YYYY"))
                    .join(", ")}
                </p>
              )}
            </div>

            {data?.amenities?.length > 0 && (
              <h4 className="text-black mt-2">Amenities</h4>
            )}
            <div className="d-flex justify-content-between align-items-start gap-3 mt-3">
              <div>
                {data?.amenities
                  .slice(0, Math.ceil(data?.amenities?.length / 2))
                  .map((amenity, index) => (
                    <p key={index}>~ {amenity}</p>
                  ))}
              </div>
              <div>
                {data?.amenities
                  .slice(Math.ceil(data?.amenities?.length / 2))
                  .map((amenity, index) => (
                    <p key={index}>~ {amenity}</p>
                  ))}
              </div>
            </div>
            <h4 className="text-black mt-2">Overview</h4>
            <p className="fs-14">{data?.overview}</p>
          </div>
          <div className="review-section mt-2">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="mb-0">Reviews</h3>
            </div>
            {data?.reviews?.map((review) => (
              <ReviewCard review={review} />
            ))}

            {data?.reviews?.length === 0 && (
              <div className="text-center p-5">No Reviews!</div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
